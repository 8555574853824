export const getCurrencySymbol = (currency: string) => {
  switch (currency) {
    case 'eur':
      return '€';
    case 'usd':
      return '$';
    default:
      return 'RON';
  }
};
