import { gql } from '@apollo/client';

export const CreateParkingSessionMutation = gql`
  mutation CreateParkingSessionMutation(
    $gateId: ID!
    $parkingId: ID!
    $userId: ID!
    $startedAt: DateTime!
  ) {
    createSession(
      data: {
        gate: { connect: { id: $gateId } }
        parking: { connect: { id: $parkingId } }
        user: { connect: { id: $userId } }
        startedAt: $startedAt
      }
    ) {
      id
      startedAt
      endedAt
      gate {
        id
      }
      parking {
        id
      }
      user {
        id
        email
      }
    }
  }
`;
