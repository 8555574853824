import { gql } from "@apollo/client";

export const CreateParkingIssueMutation = gql`
  mutation CreateParkingIssueMutation(
    $reason: String
    $userId: ID!
    $parkingId: ID!
  ) {
    createParkingIssue(
      data: {
        reason: $reason
        user: { connect: { id: $userId } }
        parking: { connect: { id: $parkingId } }
      }
    ) {
      reason
    }
  }
`;
