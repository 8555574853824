import { gql } from '@apollo/client';

export const GetAllVendorsQuery = gql`
  query getAllVendorsQuery {
    vendors {
      isAvailable
      full_address
      id
      image {
        id
        image {
          publicUrl
        }
      }
      lat
      lng
      isSelected @client
    }
  }
`;
