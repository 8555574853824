import { gql } from '@apollo/client';

export const GetVendorByIdQuery = gql`
  query GetVendorByIdQuery($id: ID) {
    vendor(where: { id: $id }) {
      id
      full_address
      isAvailable
      lat
      lng
      name
      type
      image {
        id
        image {
          publicUrl
        }
      }
      rewards {
        id
        description
        cost
        title
        image {
          image {
            publicUrl
          }
        }
      }
      isSelected @client
    }
  }
`;
