import { toast } from 'react-hot-toast';

export const toastManager = {
  acceptedInviteSuccess(message: string) {
    toast.success(`${message}\nRedirecting...`, {
      duration: 3_000,
    });
  },
  acceptedInviteError() {
    toast.error('Error accepting invite, please try again later');
  },
  companyNotFound() {
    toast.error('Company not found ❌');
  },
  smsValidated() {
    toast.success('SMS validated\nRedirecting...');
  },
  passwordChanged() {
    toast.success('Password changed \nRedirecting...');
  },
  requestAccessSuccess() {
    toast.success('We will review the request and get back to you soon');
  },
  couldNotCreateVendorClaim() {
    toast.error('Could not create vendor claim');
  },
  claimInvalidCode() {
    toast.error('Code is invalid');
  },
  claimCodeExpired() {
    toast.error('Code expired');
  },
  claimInsufficientBalance() {
    toast.error('User does not have enough rastel coins');
  },
  couldNotAcknowledgeParkingEvent() {
    toast.error('Could not acknowledge parking event');
  },
  subscriptionCancelled() {
    toast.success('Subscription cancelled');
  },
  subscriptionCreationError(message: string) {
    toast.error(`Error creating subscription ${message}`);
  },
};
