import HyperModal from 'react-hyper-modal';
import Rating from 'react-rating';
import StarEmptyIcon from '../../../assets/svg/star_empty.svg';
import StarFilledIcon from '../../../assets/svg/star_filled.svg';
import { Button } from '../Button';
import { Controller, useForm } from 'react-hook-form';
import { ReviewType } from '../../../utils/utilTypes';
import { CreateReviewMutation } from '../../../graphql/reviews/createReviewMutation';
import { useMutation } from '@apollo/client';
import { useAuth } from '../../../lib/auth';
import { FeedbackModalProps } from './props';
import { useTranslation } from 'react-i18next';

export const FeedbackModal = ({ isOpen, onClose }: FeedbackModalProps) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { isValid, isDirty, errors },
    reset,
  } = useForm<ReviewType>({
    mode: 'onChange',
    defaultValues: {
      rating: null,
      description: '',
    },
  });
  const { t } = useTranslation();
  const { currentUser } = useAuth();

  const [createReview, { loading }] = useMutation(CreateReviewMutation);

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = async ({ description, rating }: ReviewType) => {
    try {
      if (!currentUser) {
        return;
      }
      await createReview({
        variables: {
          description,
          rating,
          userId: currentUser.id,
        },
      });
      handleClose();
    } catch (err: any) {
      console.log(err.message);
    }
  };

  return (
    <HyperModal isOpen={isOpen} requestClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col my-10 mx-6">
          <div className="my-6">
            <h2 className="text-xl font-bold text-center text-primary">
              {t('reviewModalTitle')}
            </h2>
            <h4 className="font-xs font-light text-center">
              {t('reviewModalDescription')}
            </h4>
          </div>

          <div>
            <h2 className="text-sm ">
              {"1. "} {t('reviewStep1')}
            </h2>
            <Controller
              control={control}
              name="rating"
              rules={{
                required: t('ratingRequired'),
              }}
              render={({ field: { ref, ...field } }) => (
                // @ts-ignore
                <Rating
                  {...field}
                  // @ts-ignore
                  initialRating={field.value}
                  className="my-3 mx-0 text-center w-full"
                  stop={5}
                  emptySymbol={<StarEmptyIcon />}
                  fullSymbol={<StarFilledIcon />}
                />
              )}
            />
          </div>

          <div className="mt-6">
            <h2 className="text-sm">{"2."} {t('reviewStep2')}</h2>
            <textarea
              rows={4}
              className={
                'w-full border mt-2 p-4 active:outline-none focus:outline-none'
              }
              {...register('description')}
            />
          </div>

          <div className="mt-6 flex [&>button]:w-full">
            <Button
              type="primary"
              onClick={() => { }}
              disabled={!isValid || loading}
            >
              {t('submitReview')}
            </Button>
          </div>
        </div>
      </form>
    </HyperModal>
  );
};
