import { gql } from "@apollo/client";

export const CreateRewardCustomMutation = gql`
  mutation createRewardCustom($campaignId: ID!) {
    createRewardCustom(campaignId: $campaignId) {
      id
      isClaimed
      user {
        name
      }
    }
  }
`;
