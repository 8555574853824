import { gql } from '@apollo/client';

export const GetParkingByIdQuery = gql`
  query GetParkingByIdQuery($id: ID, $alias: String) {
    parkings(
      where: { OR: [{ id: { equals: $id } }, { alias: { equals: $alias } }] }
    ) {
      id
      name
      lat
      lng
      full_address
      layoutType
      networkingType
      capacity
      gatesCount
      occupied
      devices(orderBy: { order: asc }) {
        mqttId
        gatesCount
        order
        gates(orderBy: { order: asc }) {
          id
          alias
          order
          capacity
          status
          sessions {
            id
            user {
              id
            }
            gate {
              id
            }
            startedAt
            endedAt
            createdAt
          }
        }
      }
      photo {
        altText
        image {
          publicUrl
        }
        altText
      }

      campaigns {
        id
        startsAt
        endsAt
      }
      parkingQuests(where: { quest: { isActive: { equals: true } } }) {
        quest {
          id
          title
          description
          reward
        }
      }
      subscriptionPlans {
        id
        name
        price
        interval
        currency
        description {
          document
        }
      }
      isUnavailable
      requiresApproval
      requiresIdVerification
      status
      isInUnlockRadius @client
      isSelected @client
      referencedBy {
        id
      }
    }
  }
`;
