import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useCallback, useRef } from 'react';
import {
  GeolocateControl,
  GeolocateControlRef,
  Map as MapGL,
  MapRef,
  Marker,
} from 'react-map-gl';
import LeaveACommentIcon from '../../../assets/svg/leave_a_comment.svg';
import { useParkings } from '../../../hooks/useParkings';
import { ParkingType, VendorType } from '../../../utils/utilTypes';
import { withinRegion } from '../../../utils/utilsDistance';
import { Loader } from '../../Loader';
import { Button } from '../Button';
import { useTranslation } from 'react-i18next';
import { MapProps } from './props';
import Image from 'next/image';

export const Map = React.memo(function MapWithoutMemoization({
  isUserLocationLoaded = false,
  isGeolocationDenied,
  center,
  zoom,
  parkingMarkers = [],
  vendorMarkers = [],
  onParkingMarkerClick,
  onVendorMarkerClick,
  latestEvent,
  onMapLoad,
  onLeaveACommentClick,
}: MapProps) {
  const { t } = useTranslation();
  const {
    operations: { setParkingsInRadius },
  } = useParkings();

  const mapRef = useRef<MapRef>(null);
  const geolocateControlRef = useRef<GeolocateControlRef>(null);

  const fitBounds = useCallback(({ longitude, latitude }: any) => {
    mapRef.current?.flyTo({
      center: [longitude, latitude],
      zoom: 8,
      duration: 2000,
    });
  }, []);

  const computeMarkersInRadius = (position: any) => {
    const parkingsThatQualifyInRadius: string[] = [];

    parkingMarkers.forEach((m: ParkingType) => {
      const markerPoint = { lat: m.lat, lng: m.lng };

      if (
        withinRegion(
          position,
          process.env.NEXT_PUBLIC_GEOFENCE_RADIUS_IN_METERS
        )(markerPoint)
      ) {
        parkingsThatQualifyInRadius.push(m.id);
      }
    });

    setParkingsInRadius(parkingsThatQualifyInRadius);
  };

  const renderParkingMarker = (marker: ParkingType, index: number) => {
    return (
      <Marker
        key={index}
        latitude={parseFloat(marker.lat)}
        longitude={parseFloat(marker.lng)}
        offset={[0, 0]}
      >
        <button
          disabled={marker?.isAtFullCapacity}
          className={`
            border-none w-[32px] h-[32px] disabled:cursor-none -mt-[16px] -ml-[16px] rounded-full text-primary flex justify-center items-center text-xs font-bold
            ${
              marker.isAtFullCapacity ||
              (marker.isDisabled && 'bg-gray-dark text-black')
            }
            ${marker.isSelected && 'bg-selected text-white'}
            ${
              marker.isInUnlockRadius ? 'bg-primary text-white' : 'bg-secondary'
            }
          `}
          onClick={() => {
            fitBounds({ latitude: marker.lat, longitude: marker.lng });
            onParkingMarkerClick(marker);
          }}
        >
          <>{marker.capacity - marker.occupied || 0}</>
        </button>
      </Marker>
    );
  };

  const renderVendorMarker = (marker: VendorType, index: number) => {
    return (
      <Marker
        key={index}
        latitude={parseFloat(marker.lat)}
        longitude={parseFloat(marker.lng)}
        offset={[0, 0]}
      >
        <button
          className="w-[48px] h-[48px] -mt-[24px] -ml-[24px] border-white border-[2px] rounded-full text-primary flex justify-center items-center text-xs font-bold bg-secondary"
          onClick={() => {
            fitBounds({ latitude: marker.lat, longitude: marker.lng });
            onVendorMarkerClick(marker);
          }}
        >
          <Image
            src={marker.image.image.publicUrl}
            width={48}
            height={48}
            alt={marker.name}
          />
        </button>
      </Marker>
    );
  };

  const renderContainer = () => {
    if (isGeolocationDenied) {
      return (
        <div className="flex flex-col justify-center items-center h-full">
          <div className="w-[60%] text-primary text-sm mt-[10px] mb-5 text-center">
            {t('locationWarning')}
          </div>
          <div>
            <Button
              onClick={() => {
                window.location.reload();
              }}
              type="primary"
            >
              {t('refresh')}
            </Button>
          </div>
        </div>
      );
    }
    if (!isUserLocationLoaded) {
      return <Loader text="Fetching locations..." />;
    }

    return (
      <>
        <MapGL
          initialViewState={{
            longitude: parseFloat(center.lng),
            latitude: parseFloat(center.lat),
            zoom: zoom,
          }}
          attributionControl={false}
          mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN}
          mapStyle="mapbox://styles/robikovacs/cl5xo97h6001j14og1bkic933"
          ref={mapRef}
          onLoad={() => {
            geolocateControlRef.current?.trigger();
            onMapLoad(mapRef);
          }}
        >
          <GeolocateControl
            ref={geolocateControlRef}
            trackUserLocation={true}
            showUserLocation={true}
            position="bottom-left"
            onGeolocate={(position) => {
              if (position.coords) {
                computeMarkersInRadius({
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                });
              }
            }}
            style={{
              backgroundColor: 'white',
              width: '48px',
              height: '48px',
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
          <div
            className="absolute bottom-[42px] right-[14px] rounded-full border border-gray-light bg-white flex p-1 justify-center items-center [&>svg]:scale-[.6]"
            onClick={onLeaveACommentClick}
          >
            <LeaveACommentIcon />
          </div>
          {parkingMarkers.map((marker: ParkingType, index: number) => {
            return renderParkingMarker(marker, index);
          })}
          {vendorMarkers.map((marker: VendorType, index: number) => {
            return renderVendorMarker(marker, index);
          })}
        </MapGL>
      </>
    );
  };

  return (
    <div className="flex flex-1 w-full h-full items-center justify-center">
      {renderContainer()}
    </div>
  );
});
