import { gql } from '@apollo/client';

export const SubscribeToPlanMutation = gql`
  mutation SubscribeToPlan(
    $subscriptionPlanId: ID!
    $parkingSpotId: ID!
    $locale: String!
  ) {
    subscribeToPlan(
      subscriptionPlanId: $subscriptionPlanId
      parkingSpotId: $parkingSpotId
      locale: $locale
    )
  }
`;
