import { CompletedQuestType, ParkingQuest } from './utilTypes';

export const calculateIfQuestIsCompleted = (
  pq: ParkingQuest,
  completedQuests: CompletedQuestType[]
) => {
  // NOTE: this works only for daily quests
  if (!pq.quest) return false;

  const relevantCompletedQuests = completedQuests.filter(
    (cq) => cq.quest.id === pq.quest.id
  );
  // @ts-ignore
  const dailyQuest = relevantCompletedQuests.findLast(
    (cq: CompletedQuestType) => cq.quest.id === pq.quest.id
  );

  if (!dailyQuest) return false;

  const isCompletedDailyQuest =
    new Date(dailyQuest?.createdAt).toDateString() ===
    new Date().toDateString();

  return isCompletedDailyQuest;
};
