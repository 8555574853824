import {
  GateOption,
  GateType,
  LatestEventType,
  ParkingSession,
  ParkingType,
  UserType,
} from './utilTypes';
import {
  isUserIdentityVerified,
  isUserParkingRequestApproved,
} from './utilsUser';

export const isRequestInexistent = (
  user: UserType | null,
  parking: ParkingType
) => {
  return (
    parking.requiresApproval && !isUserParkingRequestApproved(user, parking)
  );
};

export const isRequestPendingApproval = (parking: ParkingType) => {
  return parking?.requiresApproval && parking?.status === 'pending';
};

export const isIDValidationCase = (
  user: UserType | null,
  parking: ParkingType
) => {
  return parking.requiresIdVerification && !isUserIdentityVerified(user);
};

export const isSubscriptionCase = (
  user: UserType | null,
  parking: ParkingType,
  userSubscriptions: any[] = []
) => {
  if (!user || parking.subscriptionPlans?.length === 0) {
    return false;
  }

  const hasActiveSubscription = parking.subscriptionPlans.some((plan) => {
    const foundMatchingSubscription = userSubscriptions.find(
      (s) => s.metadata.subscriptionPlanId === plan.id
    );

    if (!foundMatchingSubscription) {
      return false;
    }

    return foundMatchingSubscription.status === 'active';
  });

  return !hasActiveSubscription;
};

export const getParkingGatesCount = (parking: ParkingType) => {
  if (!parking?.devices) {
    return 0;
  }

  if (parking?.devices.length === 0) {
    return 1;
  }

  return parking.devices.reduce((acc, device) => {
    return acc + device.gatesCount;
  }, 0);
};

export const buildParkingGateOptions = (
  parking: ParkingType,
  currentUser: UserType | null
) => {
  const gateOptions: GateOption[] = [];

  if (!parking?.devices) {
    return gateOptions;
  }

  let count = 0;

  const activeSession = getActiveSession(parking, currentUser);

  parking.devices.forEach((device, idx) => {
    for (let i = 1; i <= device.gates.length; i++) {
      const currentGate = device.gates[i - 1];
      gateOptions.push({
        value: `${device.mqttId}/${currentGate.order}`,
        label: currentGate.alias,
        capacity: currentGate.capacity,
        isSingularGate: checkIfGateIsSingular(currentGate),
        canUnlock: checkIfCanUnlockGate(
          currentGate,
          currentUser,
          activeSession
        ),
      });
    }
    count = device.gatesCount;
  });

  return gateOptions;
};

export const checkIfGateIsSingular = (gate: GateType) => {
  return gate?.capacity === 1;
};

export const isOwnerOfSession = (
  session: ParkingSession,
  user: UserType | null
) => {
  return session.user.id === user?.id;
};

export const checkIfCanUnlockGate = (
  gate: GateType,
  user: UserType | null,
  activeSession: ParkingSession | null
) => {
  if (activeSession && activeSession.gate.id !== gate.id) {
    return false;
  }

  const isGateSingular = checkIfGateIsSingular(gate);

  if (!isGateSingular || gate.sessions.length === 0) {
    return gate.status === 'available';
  }

  // Singular gate checks below

  const orderedSessions = gate.sessions.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  const latestSession = orderedSessions[0];

  if (!latestSession) return true;

  const isOngoingSession = latestSession.startedAt && !latestSession.endedAt;

  const isOwner = isOwnerOfSession(latestSession, user);
  if (isOngoingSession && isOwner) return true;
  if (isOngoingSession && !isOwner) return false;

  return true;
};

const getActiveSession = (
  parking: ParkingType,
  currentUser: UserType | null
) => {
  if (!parking?.devices) return null;

  const sessions = parking.devices.flatMap((device) => {
    return device.gates.flatMap((gate) => {
      return gate.sessions.filter((session) => {
        return (
          session.endedAt === null &&
          isOwnerOfSession(session, currentUser) &&
          checkIfGateIsSingular(gate)
        );
      });
    });
  });

  return sessions[sessions.length - 1] || null;
};

export const getActiveSessionFromLatestEvent = (event: LatestEventType) => {
  if (!event) return null;

  const sessions = event.gate.sessions.filter((session) => {
    return session.endedAt === null && checkIfGateIsSingular(event.gate);
  });

  return sessions[sessions.length - 1] || null;
};
