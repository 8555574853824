import { DocumentRendererProps } from '@keystone-6/document-renderer';

export const renderers: DocumentRendererProps['renderers'] = {
  // use your editor's autocomplete to see what other renderers you can override
  inline: {
    bold: ({ children }) => {
      return <strong>{children}</strong>;
    },
  },
  block: {
    paragraph: ({ children, textAlign }) => {
      return <p style={{ textAlign, fontSize: 13, margin: 5 }}>{children}</p>;
    },
    list: ({ type, children }) => {
      return (
        <ul className="list-disc pl-4 flex flex-col gap-2">
          {children.map((child, index) => (
            <li key={index}>{child}</li>
          ))}
        </ul>
      );
    },
  },
};
