/// <reference types="vite-plugin-svgr/client" />

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreditCardIcon from '../../../assets/svg/credit_card.svg?react';
import IdDocumentIcon from '../../../assets/svg/id_document.svg?react';
import RastelCoinIcon from '../../../assets/svg/rastel_coin.svg?react';
import RequiresApprovalIcon from '../../../assets/svg/requires_approval.svg?react';
import UnlockIcon from '../../../assets/svg/unlock.svg?react';
import { GateSelector } from '../../../features/parkings/GateSelector';
import { LatestEventQuery } from '../../../graphql/events/latestEventQuery';
import { GetParkingByIdQuery } from '../../../graphql/parkings/getParkingByIdQuery';
import { GetParkingsWithinDistance } from '../../../graphql/parkings/getParkingsWithinDistance';
import { RequestAccessToParkingMutation } from '../../../graphql/parkings/requestAccessToParkingMutation';
import { UnlockParkingByIdMutation } from '../../../graphql/parkings/unlockParkingByIdMutation';
import { SubscribeToPlanMutation } from '../../../graphql/stripe/subscriptionPlan/subscribeToPlanMutation';
import { useAuth } from '../../../lib/auth';
import {
  CompletedQuestType,
  GateOption,
  ParkingQuest,
  ParkingType,
  SubscriptionPlanType,
} from '../../../utils/utilTypes';
import {
  buildParkingGateOptions,
  isIDValidationCase,
  isRequestInexistent,
  isRequestPendingApproval,
  isSubscriptionCase,
} from '../../../utils/utilsParking';
import { calculateIfQuestIsCompleted } from '../../../utils/utilsQuests';
import { shortenText } from '../../../utils/utilsString';
import { toastManager } from '../../../utils/utilsToast';
import { Button } from '../Button';
import { CardParkingDetailLoadingSkeleton } from './Skeleton';
import { CardParkingDetailProps } from './props';
import { GetCurrentUserSubscriptionsQuery } from '../../../graphql/stripe/subscriptionPlan/getCurrentUserSubscriptions';
import { SubscriptionPlanModal } from '../SubscriptionPlanModal';
import InfoIcon from '../../../assets/svg/info.svg';
import { usePaymentPlanDetailsSlice } from '../../../slices/usePaymentPlanDetailsSlice';
import { getCurrencySymbol } from './utils';

export const CardParkingDetail = ({
  parking,
  isLoading,
  handleStartPolling,
  isPollingForUnlockEvent,
  userSubscriptions,
}: CardParkingDetailProps) => {
  const [unlockParkingById, { data, loading: isLoadingUnlocking, error }] =
    useMutation(UnlockParkingByIdMutation);
  const [requestAccess, { loading: isLoadingRequestAccess }] = useMutation(
    RequestAccessToParkingMutation
  );
  const [
    subscribe,
    { data: subscribeData, loading: isLoadingSubscribe, error: subscribeError },
  ] = useMutation(SubscribeToPlanMutation);

  const subscriptionPlans = parking?.subscriptionPlans || [];
  const isParkingSpotWithSubscriptionPlan = subscriptionPlans.length > 0;

  const router = useRouter();
  const { currentUser } = useAuth();
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;
  const gateAlias = router.query.gate as String;
  const { handleSelectPlan } = usePaymentPlanDetailsSlice();

  // Initialize selectedGate based on gateAlias
  const initialSelectedGate = useMemo(() => {
    if (gateAlias) {
      return buildParkingGateOptions(parking, currentUser).find(
        (o) => o.label.toString() === gateAlias.toString()
      );
    }
    return null;
  }, [parking, currentUser, gateAlias]);

  const [selectedGate, setSelectedGate] = useState<
    GateOption | null | undefined
  >(initialSelectedGate);

  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState<
    string | null
  >(null);

  if (isLoading || !parking) return <CardParkingDetailLoadingSkeleton />;

  const isSingularLayout =
    parking?.layoutType === 'singular' ||
    parking?.layoutType === 'internal_testing_purposes';
  const parkingQuests = parking?.parkingQuests || [];
  const hasAnyRequirements =
    parking.requiresApproval ||
    parking.requiresIdVerification ||
    subscriptionPlans?.length > 0;

  const userHasActiveSubscriptionPlan = subscriptionPlans.some((sp) => {
    return userSubscriptions.some((sub) => {
      return (
        sub.metadata.subscriptionPlanId === sp.id && sub.status === 'active'
      );
    });
  });
  const hasParkingQuests = parkingQuests.length > 0;
  const canShowGateSelector = () => {
    if (isSingularLayout) {
      return false;
    }

    if (parking.isInUnlockRadius) {
      if (isParkingSpotWithSubscriptionPlan) {
        return userHasActiveSubscriptionPlan;
      }
      return true;
    }

    return false;
  };

  const getGateValue = (
    selectedGate: GateOption | null | undefined,
    parking: ParkingType
  ) => {
    if (selectedGate) {
      return selectedGate.value;
    }

    if (
      parking.layoutType === 'singular' &&
      parking.networkingType === 'mqtt'
    ) {
      const options = buildParkingGateOptions(parking, currentUser);
      return options[0].value;
    }
  };

  const handleShowInfoForPlanClick = (subscription: SubscriptionPlanType) => {
    handleSelectPlan(subscription);
  };

  const handleSelectedGate = (gate: GateOption) => {
    setSelectedGate(gate);
  };

  const handleUnlock = async () => {
    if (!currentUser) {
      console.log('User is not logged in');
      return null;
    }

    try {
      await unlockParkingById({
        variables: {
          parkingId: parking.id,
          type: 'manual',
          gate: getGateValue(selectedGate, parking),
        },
        refetchQueries: [LatestEventQuery, GetParkingsWithinDistance],
      });

      if (parking.networkingType === 'sim') {
        handleStartPolling();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onRequestAccess = async () => {
    try {
      await requestAccess({
        variables: {
          parkingId: parking.id,
        },
        refetchQueries: [GetParkingByIdQuery],
      });
      // TODO: Start polling for parking status?
      toastManager.requestAccessSuccess();
    } catch (err) {
      console.log(err);
    }
  };

  const onSubscribeClick = async () => {
    try {
      console.log('subscribing to subscription plan');
      const {
        data: { subscribeToPlan: checkoutLink },
      } = await subscribe({
        variables: {
          subscriptionPlanId: selectedSubscriptionPlan,
          parkingSpotId: parking.id,
          locale: activeLanguage,
        },
      });

      if (checkoutLink.includes('https://')) {
        router.push(checkoutLink);
      } else {
        toastManager.subscriptionCreationError(checkoutLink);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onPickSubscriptionPlan = (subscriptionPlan: SubscriptionPlanType) => {
    if (
      selectedSubscriptionPlan === subscriptionPlan.id ||
      userHasActiveSubscriptionPlan
    ) {
      return;
    }
    setSelectedSubscriptionPlan(subscriptionPlan.id);
  };

  const renderQuestCard = (
    pq: ParkingQuest,
    userCompletedQuests: CompletedQuestType[]
  ) => {
    const isCompleted = calculateIfQuestIsCompleted(pq, userCompletedQuests);

    return (
      <div
        className={`p-3 flex-shrink-0 border border-black rounded-lg max-w-[260px] ${
          isCompleted && 'border-green-600 border-2'
        }`}
        key={pq.id}
      >
        <div className="flex justify-between items-center gap-4">
          <div className="text-primary font-bold flex gap-2">
            {pq.quest.title} {isCompleted && <div>✅</div>}
          </div>
          <div className="text-primary font-bold flex gap-1 items-center">
            {pq.quest.reward}x <RastelCoinIcon />
          </div>
        </div>
        <div className="text-gray-2 text-xs w-full mt-2">
          Description {shortenText(pq.quest.description, 30)}
        </div>
      </div>
    );
  };

  const renderButtonsSection = () => {
    if (isRequestPendingApproval(parking)) {
      return (
        <Button
          disabled={true}
          loading={false}
          onClick={() => {}}
          type="primary"
        >
          {t('pendingApproval')}...
        </Button>
      );
    }

    if (parking.isUnavailable) {
      return (
        <Button
          disabled={true}
          loading={false}
          onClick={() => {}}
          type="primary"
        >
          {t('unavailable')}
        </Button>
      );
    }

    if (isRequestInexistent(currentUser, parking)) {
      return (
        <Button
          disabled={isLoadingRequestAccess}
          loading={isLoadingRequestAccess}
          onClick={() => onRequestAccess()}
          type="primary"
        >
          {t('requestAccess')}
        </Button>
      );
    }

    if (isIDValidationCase(currentUser, parking)) {
      return (
        <Button disabled={true} onClick={() => {}} type="primary">
          {t('confirmIdentity')}
        </Button>
      );
    }

    if (isSubscriptionCase(currentUser, parking, userSubscriptions)) {
      return (
        <Button
          onClick={() => onSubscribeClick()}
          type="primary"
          disabled={!selectedSubscriptionPlan}
        >
          {t('subscribeToUnlock')}
        </Button>
      );
    }

    return (
      <Button
        disabled={
          !parking?.isInUnlockRadius ||
          isLoadingUnlocking ||
          (!isSingularLayout && !selectedGate)
        }
        loading={isPollingForUnlockEvent}
        Icon={UnlockIcon}
        onClick={handleUnlock}
        type="primary"
      >
        {parking.isInUnlockRadius
          ? `${t('unlock')} ${
              isSingularLayout
                ? ''
                : !isSingularLayout && !selectedGate
                ? ''
                : selectedGate?.label
            }`
          : t('tooFarAway')}
      </Button>
    );
  };

  const renderRequirementsSection = () => {
    return (
      <div className="flex w-full  text-white items-center my-2 gap-1 ">
        <div className="text-xs">
          {t('requires')}
          {':'}
        </div>
        {hasAnyRequirements ? (
          <div className="flex gap-x-2 overflow-y-scroll">
            {parking.requiresApproval && (
              <div className="text-xs flex flex-1 gap-1 items-center w-auto">
                <div className="">
                  <RequiresApprovalIcon className="scale-75" stroke="white" />
                </div>
                <span className="whitespace-nowrap">
                  {t('parkingRequirements.approval')}
                </span>
              </div>
            )}
            {parking.requiresIdVerification && (
              <div className="text-xs flex flex-1 gap-1 items-center w-auto">
                <div className="">
                  <IdDocumentIcon className="scale-75" />
                </div>
                <span className="whitespace-nowrap">
                  {t('parkingRequirements.document')}
                </span>
              </div>
            )}
            {subscriptionPlans.length > 0 && (
              <div className="text-xs flex flex-1 gap-1 items-center w-auto">
                <div className="">
                  <CreditCardIcon className="scale-75" stroke="white" />
                </div>
                <span className="whitespace-nowrap">
                  {t('parkingRequirements.subscription')}
                </span>
              </div>
            )}
          </div>
        ) : (
          <div className="text-xs">{t('no_requirements_for_unlocking')}</div>
        )}
      </div>
    );
  };

  const renderSubscritionPlans = () => {
    return subscriptionPlans
      .map((sp) => {
        const isActive = userSubscriptions.some((sub: any) => {
          return (
            sub.metadata.subscriptionPlanId === sp.id && sub.status === 'active'
          );
        });

        return {
          ...sp,
          isActive,
        };
      })
      .sort((a, b) => {
        if (a.isActive) return -1;
        if (b.isActive) return 1;
        return 0;
      })
      .map((sp) => {
        const isSelected = selectedSubscriptionPlan === sp.id;
        return (
          <div
            key={sp.id}
            className={`flex flex-col  grow-0 items-start border p-2 rounded-md shrink-0 ${
              isSelected ? 'bg-primary text-white' : 'border-gray-300'
            } ${sp.isActive && 'border-2 border-green-600'}`}
            onClick={() => onPickSubscriptionPlan(sp)}
          >
            <div className="flex items-center gap-4">
              <div className="flex flex-col">
                <div className="flex gap-2">
                  <div className="text-xs font-bold shrink-0">{sp.name}</div>
                </div>
                <div className="text-xs">
                  {sp.price} {getCurrencySymbol(String(sp.currency))} /{' '}
                  {t(`subscriptions.interval.${sp.interval}`)}{' '}
                  {sp.isActive && '✅'}
                </div>
              </div>
              <div
                className="w-5 h-5 active:opacity-50"
                onClick={() => handleShowInfoForPlanClick(sp)}
              >
                <InfoIcon />
              </div>
            </div>
          </div>
        );
      });
  };

  return (
    <div className="flex flex-1 h-full flex-col">
      <div className="rounded-t-general shadow-lg h-[200px] overflow-hidden mb-3 relative">
        {parking?.photo && (
          <Image
            priority
            className="brightness-30"
            src={parking?.photo?.image?.publicUrl}
            layout="fill"
            objectFit="cover"
            alt={parking?.photo?.image?.altText}
          />
        )}
        <div className="absolute w-full mx-auto transform  bottom-2 ">
          <div className="w-11/12 mx-auto flex flex-col">
            <div className="flex justify-between items-center">
              <div className="text-lg w-4/6 font-bold text-white overflow-hidden whitespace-nowrap overflow-ellipsis">
                {parking.name}
              </div>
              <div className="text-xs w-2/6 flex justify-end gap-2 text-white">
                {t('capacity')}
                {':'} <span className="font-bold">{parking.capacity}</span>
              </div>
            </div>
            <div className="flex justify-between items-center mt-2 ">
              <div className="text-xs w-1/2 max-w-[70%] text-gray-dark overflow-hidden whitespace-nowrap overflow-ellipsis">
                {parking.full_address || t('noAddressProvided')}
              </div>
              <div className="text-xs w-1/2 flex justify-end gap-2 text-white">
                {t('nr_of_gates')}
                {':'} <span className="font-bold">{parking.gatesCount}</span>
              </div>
            </div>
            <div className="text-xs w-1/2 max-w-[70%] text-gray-dark overflow-hidden whitespace-nowrap overflow-ellipsis">
              <a
                href={`https://www.google.com/maps/dir//${parking.lat},${parking.lng}`}
                rel="noreferrer"
                target="_blank"
                className="underline"
              >
                {' '}
                {t('getDirections')}{' '}
              </a>
            </div>
            <div className="flex items-center">
              {renderRequirementsSection()}
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 pb-4 flex flex-col">
        <div className="h-[0.5px] my-1" />
        <div>
          <span
            className={`${
              hasParkingQuests
                ? 'text-black font-bold font-mono'
                : 'text-gray-dark'
            } text-xs   `}
          >
            {hasParkingQuests ? t('parkingQuests') : t('noParkingQuests')}
          </span>
          <div
            className={`flex w-full flex-row gap-4  overflow-y-scroll ${
              hasParkingQuests ? 'pb-2 mt-2' : 'pb-0'
            } `}
          >
            {parkingQuests?.map((pq) =>
              renderQuestCard(pq, currentUser?.completedQuests || [])
            )}
          </div>
        </div>
        {subscriptionPlans.length > 0 ? (
          <div className="flex flex-col my-1">
            <div>
              {subscriptionPlans?.length > 0 && (
                <div className="text-xs font-bold font-mono">
                  {t('subscriptions.cardTitle')}
                </div>
              )}
            </div>
            <div className="flex flex-row overflow-x-scroll gap-1 py-2">
              {renderSubscritionPlans()}
            </div>
          </div>
        ) : null}
        <div className="mt-4">
          {canShowGateSelector() && (
            <GateSelector
              parking={parking}
              selectedGate={selectedGate}
              onSelectGate={handleSelectedGate}
            />
          )}
        </div>
        <div className="flex flex-1 flex-col justify-end mt-2">
          {renderButtonsSection()}
          {error && (
            <div className="text-xs mx-auto mt-2 text-danger text-center">
              {/* {t('errorUnlockingParking')}
              {':'}  */}
              {error.message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
