import HyperModal from 'react-hyper-modal';
import { SubscriptionPlanModalProps } from './props';
import InfoIcon from '../../../assets/svg/info.svg';
import { useTranslation } from 'react-i18next';
import { DocumentRenderer } from '@keystone-6/document-renderer';
import { Button } from '../Button';
import { renderers } from '../../../utils/utilsDocumentRenderers';

export const SubscriptionPlanModal = ({
  isOpen,
  onClose,
  subscriptionPlan,
}: SubscriptionPlanModalProps) => {
  const { t } = useTranslation();

  const isEmptyDescrition =
    !subscriptionPlan ||
    subscriptionPlan?.description?.document[0]?.children[0]?.text === '';

  return (
    <HyperModal isOpen={isOpen} requestClose={onClose}>
      <div className="h-screen flex flex-col gap-4 mt-12 p-6">
        <div className="flex justify-center">
          <div className="w-12 h-12 xxs:w-16 xxs:h-16">
            <InfoIcon />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center text center text-xl text-primary font-bold ">
          <h2>{subscriptionPlan?.name}</h2>
          <div className="text-2xl">
            {subscriptionPlan?.price}
            {subscriptionPlan?.currency} / {subscriptionPlan?.interval}{' '}
          </div>
        </div>
        {!isEmptyDescrition && (
          <div className="p-2 flex flex-col gap-4">
            <div className="font-bold">{t('subscriptions.whatYouGet')}:</div>
            <div>
              <DocumentRenderer
                document={subscriptionPlan?.description?.document}
                renderers={renderers}
              />
            </div>
          </div>
        )}
        <div className="flex justify-center">
          <Button type="primary" onClick={onClose}>
            {t('close')}
          </Button>
        </div>
      </div>
    </HyperModal>
  );
};
