import { getDistance } from "geolib";

export const withinRegion = (position: any, radius: any) => {
  const to = { latitude: position.lat, longitude: position.lng };

  return (point: any) => {
    const from = { latitude: point.lat, longitude: point.lng };
    return getDistance(from, to) <= radius;
  };
};

export const distanceBetweenTwoPoints = (point1: any, point2: any) => {
  const to = {
    latitude: parseFloat(point1.lat),
    longitude: parseFloat(point1.lng),
  };
  const from = {
    latitude: parseFloat(point2.lat),
    longitude: parseFloat(point2.lng),
  };

  return getDistance(from, to);
};
