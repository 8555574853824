import React from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { usePosition } from 'use-position';
import styles from '../assets/styles/pages/BrowsePage.module.sass';
import { Drawer } from '../components/common/Drawer';
import { Map } from '../components/common/Map';
import { FeedbackModal } from '../components/common/FeedbackModal';
import { StatusPopup } from '../components/common/StatusPopup';
import { IdentityPopup } from '../components/common/IdentityPopup';
import { LatestEventQuery } from '../graphql/events/latestEventQuery';
import { GetParkingsWithinDistance } from '../graphql/parkings/getParkingsWithinDistance';
import { GetParkingByIdQuery } from '../graphql/parkings/getParkingByIdQuery';
import { GetAllVendorsQuery } from '../graphql/vendors/getAllVendorsQuery';
import { GetVendorByIdQuery } from '../graphql/vendors/getVendorByIdQuery';
import { useParkings } from '../hooks/useParkings';
import { useVendors } from '../hooks/useVendors';
import { DetailViewTypes, ParkingType, VendorType } from '../utils/utilTypes';
import { useAuth } from '../lib/auth';
import { isUserIdentityVerified } from '../utils/utilsUser';
import { UnlockModal } from '../components/common/UnlockModal';
import { isDateOlderThan } from '../utils/utilDates';
import { SHOW_GATES_OPEN_TIMEOUT_IN_MS } from '../components/common/StatusPopup/utils/inferTypes';
import { GetCurrentUserSubscriptionsQuery } from '../graphql/stripe/subscriptionPlan/getCurrentUserSubscriptions';
import { usePaymentPlanDetailsSlice } from '../slices/usePaymentPlanDetailsSlice';
import { SubscriptionPlanModal } from '../components/common/SubscriptionPlanModal';

const POLLING_INTERVAL_IN_MILLISECONDS = 1000;
const ZOOM = 16;
const WATCH_INTERVAL: number = Number(
  process.env.NEXT_PUBLIC_WATCH_POSITION_TIMEOUT_IN_MILLISECONDS
);

const BrowsePage: NextPage = () => {
  const [map, setMap] = useState(null);
  const watchPosition = true;

  const { currentUser } = useAuth();
  const router = useRouter();
  const { parkingId, vendorId, ref } = router.query;
  console.log({ parkingId });

  const [isGelocationDenied, setIsGelocationDenied] = useState(false);
  const [isUserLocationLoaded, setIsUserLocationLoaded] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [isPollingForUnlockEvent, setIsPollingForUnlockEvent] = useState(false);
  const [isDrawerExpanded, setIsDrawerExpanded] = useState(false);
  const [detailViewType, setDetailViewType] = useState<DetailViewTypes>(
    parkingId || ref ? 'parking' : vendorId ? 'vendor' : null
  );
  const { paymentPlan: selectedPaymentPlanDetail, clearSelectedPlan } =
    usePaymentPlanDetailsSlice();
  const {
    operations: { toggleSelectedParking, clearSelectedParking },
  } = useParkings();
  const {
    operations: { toggleSelectedVendor, clearSelectedVendor },
  } = useVendors();
  const { data: currentUserSubscriptions } = useQuery(
    GetCurrentUserSubscriptionsQuery,
    {
      skip: !currentUser,
    }
  );
  const userSubscriptions =
    currentUserSubscriptions?.getCurrentUserSubscriptionsStripe || [];

  const [mapRef, setMapRef] = useState(null);

  // TODO: Incorrect types definitions, ex: errorMessage is not defined on the usePosition output, use error instead
  const {
    latitude: lat,
    longitude: lng,
    error,
  } = usePosition(watchPosition, {
    enableHighAccuracy: true,
    maximumAge: WATCH_INTERVAL,
  });

  const focusMapOnMarker = useCallback(
    (lat, lng) => {
      if (mapRef && lat && lng) {
        mapRef.current?.flyTo({
          center: [lng, lat],
          zoom: ZOOM,
          duration: 2000,
        });
      }
    },
    [mapRef]
  );

  const [
    getParkingById,
    {
      loading: isLoadingParkingDetail,
      data: foundParkings,
      error: getByIdError,
    },
  ] = useLazyQuery(GetParkingByIdQuery);

  const parkingDetailData = foundParkings && foundParkings.parkings[0];

  const [
    getVendorById,
    {
      loading: isLoadingVendorDetail,
      data: vendorDetailData,
      error: getVendorByIdError,
    },
  ] = useLazyQuery(GetVendorByIdQuery);

  const getParkingFromQueryParam = useCallback(
    async (parkingId) => {
      try {
        const {
          data: { parkings },
        } = await getParkingById({
          variables: { id: parkingId },
          fetchPolicy: 'network-only',
        });
        const parking = parkings[0];
        focusMapOnMarker(parking.lat, parking.lng);
        setIsDrawerExpanded(true);
        toggleSelectedParking(parkingId);
      } catch (error) {
        console.log('error', error);
      }
    },
    [focusMapOnMarker, getParkingById, toggleSelectedParking]
  );

  const getParkingFromRefParam = useCallback(
    async (ref) => {
      try {
        const {
          data: { parkings },
        } = await getParkingById({
          variables: { alias: ref },
          fetchPolicy: 'network-only',
        });
        const parking = parkings[0];
        focusMapOnMarker(parking.lat, parking.lng);
        setIsDrawerExpanded(true);
        toggleSelectedParking(parking.id);
      } catch (error) {
        console.log('error', error);
      }
    },
    [focusMapOnMarker, getParkingById, toggleSelectedParking]
  );

  const getVendorFromQueryParam = useCallback(
    async (vendorId) => {
      try {
        const {
          data: { vendor },
        } = await getVendorById({
          variables: { id: vendorId },
          fetchPolicy: 'network-only',
        });
        focusMapOnMarker(vendor.lat, vendor.lng);
        setIsDrawerExpanded(true);
        toggleSelectedVendor(vendorId);
      } catch (error) {
        console.log('error', error);
      }
    },
    [focusMapOnMarker, getVendorById, toggleSelectedVendor]
  );

  const [
    loadParkingsNear,
    {
      loading: isLoadingParkings,
      data: parkingsListData,
      error: parkingsDataError,
    },
  ] = useLazyQuery(GetParkingsWithinDistance, {
    onCompleted: async () => {
      if (
        (parkingId && parkingId !== parkingDetailData?.id) ||
        (ref && ref !== parkingDetailData?.alias)
      ) {
        if (parkingId) {
          await getParkingFromQueryParam(parkingId);
          return;
        }
        if (ref) {
          await getParkingFromRefParam(ref);
        }
      }
    },
  });

  const {
    loading: isLoadingVendors,
    error: vendorsDataError,
    data: vendorsData,
  } = useQuery(GetAllVendorsQuery, {
    onCompleted: async () => {
      if (vendorId && vendorId !== vendorDetailData?.vendor?.id) {
        await getVendorFromQueryParam(vendorId);
      }
    },
  });

  const {
    data: latestEventData,
    startPolling,
    stopPolling,
  } = useQuery(LatestEventQuery);

  const parkingList = parkingsListData?.parkingsNearLocation.map(
    (p: ParkingType) => ({
      ...p,
      isAtFullCapacity: p.occupied >= p.capacity,
    })
  );

  const vendorsList =
    vendorsData?.vendors
      .map((v: VendorType) => ({
        ...v,
      }))
      .filter((v: VendorType) => v.isAvailable) || [];

  const onParkingClick = useCallback(
    async ({ id, lat = '', lng = '' }: ParkingType) => {
      router.push(`?parkingId=${id}`, undefined, { shallow: true });
      setDetailViewType('parking');
      focusMapOnMarker(lat, lng);
      setIsDrawerExpanded(true);
      await getParkingById({
        variables: { id },
        fetchPolicy: 'network-only',
      });
      toggleSelectedParking(id);
    },
    [focusMapOnMarker, getParkingById, toggleSelectedParking, router]
  );

  const onVendorClick = useCallback(
    async ({ id, lat = '', lng = '' }: VendorType) => {
      router.push(`?vendorId=${id}`, undefined, { shallow: true });
      setDetailViewType('vendor');
      focusMapOnMarker(lat, lng);
      setIsDrawerExpanded(true);
      await getVendorById({ variables: { id }, fetchPolicy: 'network-only' });
      toggleSelectedVendor(id);
    },
    [focusMapOnMarker, router]
  );

  const onClearClick = () => {
    setIsDrawerExpanded(false);
    clearSelectedParking();
    clearSelectedVendor();

    router.push('/', undefined, { shallow: true });
  };

  const handleStartPolling = () => {
    startPolling(POLLING_INTERVAL_IN_MILLISECONDS);
    setIsPollingForUnlockEvent(true);
  };

  const renderPopups = () => {
    if (!currentUser) {
      return null;
    }

    if (!isDrawerExpanded || detailViewType === 'vendor') {
      return null;
    }

    if (
      !isUserIdentityVerified(currentUser) &&
      parkingDetailData &&
      parkingDetailData?.requiresIdVerification &&
      !parkingDetailData?.isUnavailable
    ) {
      return <IdentityPopup />;
    }

    return (
      <StatusPopup
        parking={parkingDetailData}
        latestEvent={latestEventData?.latestEvent}
        userSubscriptions={userSubscriptions}
      />
    );
  };

  useEffect(() => {
    if (parkingId) {
      getParkingFromQueryParam(parkingId);
    }
  }, [parkingId]);

  useEffect(() => {
    if (latestEventData?.latestEvent?.twilioCallResponse === true) {
      stopPolling();
      setIsPollingForUnlockEvent(false);
    }
  }, [latestEventData?.latestEvent, stopPolling]);

  useEffect(() => {
    if (error) {
      setIsGelocationDenied(true);
    }
  }, [error]);

  useEffect(() => {
    if (lat && lng && !isUserLocationLoaded) {
      loadParkingsNear({
        variables: {
          lat,
          lng,
        },
      });
      setIsUserLocationLoaded(true);
    }
  }, [lat, lng]);

  if (parkingsDataError) {
    return (
      <div className={styles.browsePage}>
        Error fetching parkings data {parkingsDataError.message}:(
      </div>
    );
  }

  if (vendorsDataError) {
    return (
      <div className={styles.browsePage}>
        Error fetching vendor data {vendorsDataError.message}:(
      </div>
    );
  }

  return (
    <div className={styles.browsePage}>
      {renderPopups()}
      <Map
        map={map}
        setMap={setMap}
        isGeolocationDenied={isGelocationDenied}
        isUserLocationLoaded={isUserLocationLoaded}
        center={{ lat, lng }}
        zoom={ZOOM}
        parkingMarkers={parkingList}
        vendorMarkers={vendorsList}
        onParkingMarkerClick={onParkingClick}
        onVendorMarkerClick={onVendorClick}
        onMapClick={onClearClick}
        onMapLoad={setMapRef}
        latestEvent={latestEventData?.latestEvent}
        onLeaveACommentClick={() => setShowFeedbackModal(true)}
      />
      <Drawer
        isDrawerExpanded={isDrawerExpanded}
        detailViewType={detailViewType}
        center={{ lat, lng }}
        parkings={parkingList}
        parkingDetail={parkingDetailData}
        vendorDetail={vendorDetailData?.vendor}
        isUserLocationLoaded={isUserLocationLoaded}
        isLoadingParkings={isLoadingParkings}
        isLoadingParkingDetail={isLoadingParkingDetail}
        isLoadingVendorDetail={isLoadingVendorDetail}
        onCollapseDrawerClick={onClearClick}
        onListItemClick={onParkingClick}
        latestEvent={latestEventData?.latestEvent}
        handleStartPolling={handleStartPolling}
        isPollingForUnlockEvent={isPollingForUnlockEvent}
        userSubscriptions={userSubscriptions}
      />
      <FeedbackModal
        isOpen={showFeedbackModal}
        onClose={() => setShowFeedbackModal(false)}
      />

      <UnlockModal parkingDetail={parkingDetailData} />

      <SubscriptionPlanModal
        isOpen={!!selectedPaymentPlanDetail}
        onClose={clearSelectedPlan}
        subscriptionPlan={selectedPaymentPlanDetail}
      />
    </div>
  );
};

export default BrowsePage;
