import { useTranslation } from 'react-i18next';
import { GateSelectorProps } from './props';
import {
  buildParkingGateOptions,
  getParkingGatesCount,
} from '../../../utils/utilsParking';
import { useAuth } from '../../../lib/auth';

export const GateSelector = ({
  parking,
  selectedGate,
  onSelectGate,
}: GateSelectorProps) => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();

  return (
    <div className="flex flex-col gap-2">
      <div className="text-xs font-bold font-mono">{t('selectGate')}</div>
      <div className="flex flex-row gap-2 overflow-y-scroll  no-scrollbar pb-2">
        {buildParkingGateOptions(parking, currentUser).map((gate) => {
          return (
            <button
              disabled={!gate.canUnlock}
              onClick={() => onSelectGate(gate)}
              key={gate.value}
              className={`flex justify-center transition-all items-center rounded-full font-mono shrink-0 w-12 h-12 
              ${
                gate.value === selectedGate?.value
                  ? 'bg-primary text-white'
                  : 'bg-secondary text-black'
              }
              ${
                !gate.canUnlock
                  ? 'opacity-50 cursor-not-allowed'
                  : 'hover:bg-primary hover:text-white'
              }
            `}
            >
              {gate.label}
            </button>
          );
        })}
      </div>
    </div>
  );
};
