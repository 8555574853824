import { ParkingType, UserType } from './utilTypes';

export const isUserIdentityVerified = (user: UserType | null) => {
  if (!user) {
    return false;
  }

  if (user.stripeVerificationSession) {
    return user.stripeVerificationSession.status === 'verified';
  }

  return false;
};

export const isUserParkingRequestApproved = (
  user: UserType | null,
  parking: ParkingType
) => {
  if (!user) {
    return false;
  }

  if (user.userParkings) {
    const foundParking = user.userParkings.find(
      (userParking) => userParking.parking.id === parking.id
    );

    if (!foundParking) {
      return false;
    }

    return foundParking.status === 'approved';
  }

  return false;
};
