import Image from 'next/image';
import { CardVendorDetailProps } from './props';
import CloseIcon from '../../../assets/svg/close.svg';
import { snakeToNormal } from '../../../utils/utilsString';
import { useAuth } from '../../../lib/auth';
import RastelCoinIcon from '../../../assets/svg/rastel_coin.svg';
import UnlockIcon from '../../../assets/svg/unlock.svg';
import { VendorRewardType } from '../../../utils/utilTypes';
import { Button } from '../Button';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CreateVendorClaimMutation } from '../../../graphql/vendors/createVendorClaimMutation';
import { toastManager } from '../../../utils/utilsToast';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

export const CardVendorDetail = ({
  vendor,
  isLoading,
  onCollapseDrawerClick,
}: CardVendorDetailProps) => {
  const { currentUser } = useAuth();
  const router = useRouter();
  const userBalance = currentUser?.walletBalance || 0;
  const [selectedRewardId, setSelectedRewardId] = useState<string | null>(null);
  const [claimVendorReward, { loading, error }] = useMutation(
    CreateVendorClaimMutation
  );
  const { t } = useTranslation();

  if (isLoading) return <div>Loading...</div>;

  const selectedReward = vendor.rewards.find(
    (reward) => reward.id === selectedRewardId
  );

  const handleClaimClick = async () => {
    console.log('Claiming');
    try {
      const {
        data: { createVendorClaimCustom: claimData },
      } = await claimVendorReward({
        variables: {
          vendorRewardId: selectedRewardId,
        },
      });
      console.log('Finished claimin', claimData);
      router.push(`/vendor-claims/${claimData.id}`);
    } catch (err) {
      toastManager.couldNotCreateVendorClaim();
      console.error(err);
    }
  };

  const renderHeader = () => {
    return (
      <>
        <div className="flex items-center bg-white rounded-full w-[64px] h-[64px]">
          <Image
            src={vendor.image.image.publicUrl}
            alt={vendor.name}
            width={64}
            height={64}
          />
        </div>
        <div className="flex flex-col">
          <h1 className="text-white text-lg font-bold">{vendor.name}</h1>
          <p className="text-xs text-gray-dark">{vendor.full_address}</p>
          <div className="bg-white px-3 mt-1  py-0 font text-xs rounded-full text-primary flex justify-center items-center font-bold">
            {snakeToNormal(vendor.type)}
          </div>
        </div>
      </>
    );
  };

  const renderReward = (reward: VendorRewardType) => {
    let baseClass = 'flex items-center justify-between  border rounded-xl p-1';
    const isSelected = selectedRewardId === reward.id;
    const canClaim = reward.cost <= userBalance;

    if (canClaim) {
      baseClass += ' border-primary cursor-pointer';
    } else {
      baseClass += ' opacity-50 cursor-not-allowed';
    }

    if (isSelected) {
      baseClass += ' bg-ternary';
    } else {
      baseClass += ' bg-white';
    }

    return (
      <div
        className={`${baseClass}`}
        onClick={() => canClaim && setSelectedRewardId(reward.id)}
      >
        <div className="flex w-full gap-x-2">
          <div className="flex shrink-0  items bg-white  border-2 rounded-xl border-secondary w-[76px] h-[76px]">
            <Image
              src={reward.image.image.publicUrl}
              alt={reward.title}
              width={76}
              height={76}
            />
          </div>
          <div className="flex flex-col w-full">
            <div className="flex justify-between ">
              <h1
                className={`${
                  isSelected ? 'text-white' : 'text-primary'
                }  font-bold`}
              >
                {reward.title}
              </h1>
              <p
                className={`${
                  isSelected ? 'text-white' : 'text-primary'
                } flex font-bold gap-x-1`}
              >
                {reward.cost}{' '}
                <div className="scale-75">
                  <RastelCoinIcon scale={2} />
                </div>
              </p>
            </div>
            <p className="text-xs text-gray-2">{reward.description}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="h-full">
      <div
        className="absolute top-3 right-3 cursor-pointer"
        onClick={onCollapseDrawerClick}
      >
        <CloseIcon fill="white" stroke="white" />
      </div>
      <div className="bg-ternary flex items-center px-5 py-3 gap-x-4">
        {renderHeader()}
      </div>
      <div className="px-2 py-2  flex flex-col ">
        <div className="flex justify-between items-center">
          <h2 className="text-primary text-lg font-bold">
            {t('vendorDetail.rewards')}
          </h2>
          <p className="text-primary flex gap-x-1 font-bold">
            {t('vendorDetail.yourBalance')} {currentUser?.walletBalance}{' '}
            <RastelCoinIcon />
          </p>
        </div>
        <div className="mt-2">
          <p className="text-xs">
            {vendor.rewards.length === 0
              ? t('vendorDetail.noRewards')
              : t('vendorDetail.rewardsDescription')}
          </p>
        </div>
        <div className="mt-4 flex flex-col w-full min-h-[100px] max-h-[310px] gap-y-2 overflow-y-scroll relative">
          {vendor.rewards.map(renderReward)}
          {vendor.rewards.length > 0 && <div className="mb-8"></div>}
          {vendor.rewards.length > 0 && (
            <div className="w-full sticky bottom-5">
              <Button
                disabled={!selectedReward}
                onClick={handleClaimClick}
                className="flex justify-center items-center opacity-100 shadow-xl bg-ternary text-white border border-black"
                type="custom"
              >
                <div className="mr-2">
                  <UnlockIcon />
                </div>
                <div className="mt-1">
                  {t('vendorDetail.claim')} {selectedReward?.title}
                </div>
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
