import { ReactiveVar } from '@apollo/client';
import { currentSelectedVendorId, parkingsInUnlockRadius } from '../lib/apollo';

export function useVendors() {
  const toggleSelectedVendor = (vendorId: string) => {
    currentSelectedVendorId(vendorId);
  };

  const clearSelectedVendor = () => {
    currentSelectedVendorId(null);
  };

  return {
    operations: {
      toggleSelectedVendor,
      clearSelectedVendor,
    },
  };
}
