import { gql } from '@apollo/client';

export const GetParkingsWithinDistance = gql`
  query GetParkingsNearLocation($lat: Float!, $lng: Float!) {
    parkingsNearLocation(lat: $lat, lng: $lng) {
      id
      name
      lat
      lng
      full_address
      photo {
        altText
        image {
          publicUrl
        }
        altText
      }
      capacity
      occupied
      isSelected @client
      isInUnlockRadius @client
      isUnavailable
      requiresIdVerification
      referenceParking {
        id
        name
      }
    }
  }
`;
