import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import HyperModal from "react-hyper-modal";
import { CreateParkingIssueMutation } from "../../../graphql/parkingIssues/createParkingIssueMutation";
import { LatestEventQuery } from "../../../graphql/events/latestEventQuery";
import { useAuth } from "../../../lib/auth";
import { IssueType } from "../../../utils/utilTypes";
import { Button } from "../Button";
import { ReportIssueModalProps } from "./props";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

export const ReportIssueModal = ({
  isOpen,
  onClose,
}: ReportIssueModalProps) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { isValid, isDirty, errors },
    reset,
  } = useForm<IssueType>({
    mode: "onChange",
    defaultValues: {
      reason: "",
    },
  });
  const { currentUser } = useAuth();
  const { data: latestEventData } = useQuery(LatestEventQuery);
  const { t } = useTranslation();

  const [createIssue, { loading }] = useMutation(CreateParkingIssueMutation);

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = async ({ reason }: IssueType) => {
    try {
      if (!currentUser) {
        return;
      }
      await createIssue({
        variables: {
          reason,
          userId: currentUser.id,
          parkingId: latestEventData?.latestEvent?.parking?.id,
        },
      });
      handleClose();
    } catch (err: any) {
      console.log(err.message);
    }
  };

  return (
    <HyperModal isOpen={isOpen} requestClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col my-10 mx-6">
          <div className="my-6">
            <h2 className="text-xl font-bold text-center text-primary">
              {t("reportIssueTitle")}
            </h2>
            <h4 className="font-xs mt-4 font-light text-center">
              {t("reportIssueDescription")}
            </h4>
          </div>

          <div>
            <textarea
              rows={4}
              className={
                "w-full border mt-2 p-4 active:outline-none focus:outline-none"
              }
              {...register("reason")}
            />
          </div>

          <div className="mt-6 flex [&>button]:w-full">
            <Button
              type="primary"
              onClick={() => {}}
              disabled={!isValid || loading}
            >
              {t("submitIssue")}
            </Button>
          </div>
        </div>
      </form>
    </HyperModal>
  );
};
