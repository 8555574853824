import { create } from 'zustand';
import { SubscriptionPlanType } from '../utils/utilTypes';

type StateType = {
  paymentPlan: SubscriptionPlanType | null;
  handleSelectPlan: (plan: SubscriptionPlanType) => void;
  clearSelectedPlan: () => void;
};

export const usePaymentPlanDetailsSlice = create<StateType>((set) => ({
  paymentPlan: null,
  handleSelectPlan: (plan) => set({ paymentPlan: plan }),
  clearSelectedPlan: () => set({ paymentPlan: null }),
}));
