import { gql } from "@apollo/client";

export const RequestAccessToParkingMutation = gql`
  mutation requestAccess($parkingId: ID!) {
    requestAccess(parkingId: $parkingId) {
      id
      status
    }
  }
`;
