import { useLazyQuery, useMutation } from '@apollo/client';
import { stripe } from '../../../lib/stripe';
import { Button } from '../Button';
import { CREATE_IDENTITY_VERIFICATION_MUTATION } from '../../../graphql/stripe/identity/createIdentityVerificationSession';
import { useAuth } from '../../../lib/auth';
import { useTranslation } from 'react-i18next';

export const IdentityPopup = () => {
  const { t } = useTranslation();
  const [createVerificationSession, { loading, error }] = useMutation(
    CREATE_IDENTITY_VERIFICATION_MUTATION
  );

  const { getCurrentUser } = useAuth();

  const handleIdentityVerificationClick = async () => {
    try {
      const {
        data: { createVerificationSession: client_secret },
      } = await createVerificationSession();

      const result = await stripe?.verifyIdentity(client_secret);

      if (result?.error) {
        // TODO: getAll with where.equals
        // TODO: update found one with the errored status
        console.log('error', result.error);
      } else {
        console.log('success', result);
      }
    } catch (err) {
      console.log(err);
    } finally {
      await getCurrentUser();
    }
  };

  return (
    <div className="absolute bg-white z-status-popup left-[36px] right-[36px] top-[32px] shadow-lg flex flex-col p-6">
      <div className="text-lg text-center text-primary font-bold">
        {t('confirmYourIdentity')}
      </div>
      <div className="my-4 text-sm text-center">
        {t('pleaseConfirmYourIdentity')}
      </div>

      <Button
        className="text-sm"
        type="primary"
        onClick={handleIdentityVerificationClick}
      >
        {t('continue')}
      </Button>
    </div>
  );
};
