import Skeleton from 'react-loading-skeleton';

export const AllCardParkingLoadingSkeleton = () => (
  <div className="flex">
    {Array.from(Array(3).keys()).map((x, i) => (
      <div key={i} className="min-w-[184px] max-w-[184px] ml-3 mr-3">
        <Skeleton height={100} className="rounded-general mb-2" />
        <Skeleton height={23} className="mb-1" />
        <Skeleton height={14} />
      </div>
    ))}
  </div>
);
