import { CardParkingList } from '../CardParkingListItem';
import 'react-loading-skeleton/dist/skeleton.css';

import { CardParkingDetail } from '../CardParkingDetail';
import { DrawerProps } from './props';
import { CardVendorDetail } from '../CardVendorDetail';
import CloseIcon from '../../../assets/svg/close.svg';

import { useTranslation } from 'react-i18next';
export const Drawer = ({
  detailViewType,

  isDrawerExpanded,
  isLoadingParkings,
  isLoadingParkingDetail,
  isLoadingVendorDetail,

  isUserLocationLoaded = false,
  center,
  parkings,
  parkingDetail,
  vendorDetail,
  onCollapseDrawerClick,
  onListItemClick,
  latestEvent,
  handleStartPolling,
  isPollingForUnlockEvent,
  userSubscriptions,
}: DrawerProps) => {
  const { t } = useTranslation();
  const renderDetailContainer = () => {
    if (detailViewType === 'vendor') {
      return (
        <div className="h-full w-full  relative">
          <CardVendorDetail
            onCollapseDrawerClick={onCollapseDrawerClick}
            vendor={vendorDetail}
            isLoading={isLoadingVendorDetail}
          />
        </div>
      );
    }

    if (detailViewType === 'parking') {
      return (
        <div className=" h-full w-full overflow-hidden relative">
          <div
            className="absolute top-5 right-4 z-10 cursor-pointer"
            onClick={onCollapseDrawerClick}
          >
            <CloseIcon fill="white" className="hover:opacity-90" />
          </div>
          <CardParkingDetail
            parking={parkingDetail}
            isLoading={isLoadingParkingDetail}
            handleStartPolling={handleStartPolling}
            isPollingForUnlockEvent={isPollingForUnlockEvent}
            latestEvent={latestEvent}
            userSubscriptions={userSubscriptions}
          />
        </div>
      );
    }
  };

  const renderListContainer = () => {
    if (
      !isLoadingParkings &&
      (!parkings || !parkings.length) &&
      isUserLocationLoaded
    ) {
      return (
        <div className="text-primary text-center mx-24 text-sm font-bold flex justify-center items-center h-full">
          {t('noParkingFound')}
        </div>
      );
    }

    return (
      <div className="flex flex-col">
        <h1 className="px-3 py-2 text-black font-bold text-sm md:text-md">
          {t('nearbyParkingSpots')}
        </h1>
        <div className="h-full w-full overflow-hidden">
          <CardParkingList
            center={center}
            parkings={parkings}
            onClick={onListItemClick}
            isLoading={isLoadingParkings || !isUserLocationLoaded}
            latestEvent={latestEvent}
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className={`relative transition-drawer z-drawer rounded-tl-[12px]   rounded-tr-[12px] border-t-2 
      ${isDrawerExpanded ? 'transition-drawer-opened' : 'h-drawer-collapsed'} `}
    >
      {isDrawerExpanded ? renderDetailContainer() : renderListContainer()}
    </div>
  );
};
