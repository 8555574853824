import { ReactiveVar } from "@apollo/client";
import {
  currentSelectedParkingId,
  parkingsInUnlockRadius,
} from "../lib/apollo";

export function useParkings() {
  const toggleSelectedParking = (parkingId: string) => {
    currentSelectedParkingId(parkingId);
  };

  const clearSelectedParking = () => {
    currentSelectedParkingId(null);
  };

  const setParkingsInRadius = (parkingsId: string[]) => {
    parkingsInUnlockRadius(parkingsId);
  };

  return {
    operations: {
      toggleSelectedParking,
      clearSelectedParking,
      setParkingsInRadius,
    },
  };
}
