import { gql } from '@apollo/client';

export const UpdateParkingSessionMutation = gql`
  mutation ($id: ID!, $data: SessionUpdateInput!) {
    updateSession(where: { id: $id }, data: $data) {
      id
      startedAt
      endedAt
      gate {
        id
      }
      parking {
        id
      }
      user {
        id
        email
      }
    }
  }
`;
