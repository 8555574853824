import { TFunction } from 'i18next';
import { isDateOlderThan } from '../../../../utils/utilDates';
import {
  LatestEventType,
  ParkingType,
  Subscription,
  UserType,
} from '../../../../utils/utilTypes';
import { isSubscriptionCase } from '../../../../utils/utilsParking';
import { isUserParkingRequestApproved } from '../../../../utils/utilsUser';

export const SHOW_GATES_OPEN_TIMEOUT_IN_MS = 15_000;

const possibleTypes = (t: TFunction) => {
  return {
    selectedParking: () => ({
      type: 'selectedParking',
      title: t('selectedParkingTitle'),
      description: t('selectedParkingDescription', {
        radius: process.env.NEXT_PUBLIC_GEOFENCE_RADIUS_IN_METERS,
      }),
    }),
    withinParkingRadius: () => ({
      type: 'withinParkingRadius',
      title: t('withinParkingRadiusTitle'),
      description: t('withinParkingRadiusDescription'),
    }),
    unlockingSelectedParking: () => ({
      type: 'unlockingSelectedParking',
      title: t('unlockingSelectedParkingTitle'),
      description: t('unlockingSelectedParkingDescription'),
    }),
    // gatesAreOpen: () => ({
    //   type: 'gatesAreOpen',
    //   title: t('gatesAreOpenTitle'),
    //   description: t('gatesAreOpenDescription'),
    // }),
    unavailable: () => ({
      type: 'unavailable',
      title: t('unavailableTitle'),
      description: t('unavailableDescription'),
    }),
    subscription: () => ({
      type: 'subscription',
      title: t('subscriptionTitle'),
      description: t('subscriptionDescription'),
    }),
    requiresApproval: () => ({
      type: 'requiresApproval',
      title: t('requiresApprovalTitle'),
      description: t('requiresApprovalDescription'),
    }),
  };
};

export const inferParkingModal = (
  currentUser: UserType | null,
  t: TFunction,
  userSubscriptions: Subscription[],
  parking?: ParkingType,
  latestEvent?: LatestEventType
) => {
  if (parking?.isUnavailable) {
    return possibleTypes(t).unavailable();
  }

  if (
    parking?.requiresApproval &&
    !isUserParkingRequestApproved(currentUser, parking)
  ) {
    return possibleTypes(t).requiresApproval();
  }

  if (parking && isSubscriptionCase(currentUser, parking, userSubscriptions)) {
    return possibleTypes(t).subscription();
  }

  if (
    latestEvent?.status === 'sim_pending' &&
    latestEvent?.twilioCallResponse === false
  ) {
    return possibleTypes(t).unlockingSelectedParking();
  }

  // if (
  //   latestEvent?.twilioCallResponse &&
  //   isDateOlderThan(latestEvent.updatedAt, SHOW_GATES_OPEN_TIMEOUT_IN_MS)
  // ) {
  //   return possibleTypes(t).gatesAreOpen();
  // }

  if (parking?.isInUnlockRadius && parking?.isSelected) {
    return possibleTypes(t).withinParkingRadius();
  }

  if (parking?.isSelected) {
    return possibleTypes(t).selectedParking();
  }

  return null;
};
