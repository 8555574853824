import Skeleton from 'react-loading-skeleton';

export const CardParkingDetailLoadingSkeleton = () => (
  <div className="flex" data-testid="card-parking-detail-loading-skeleton">
    <div className="w-full">
      <Skeleton height={146} width={'100%'} className="rounded-general mb-2" />
      <Skeleton height={24} />
      <Skeleton height={12} className="mt-2 mb-4" />
      <Skeleton height={48} />
    </div>
  </div>
);
