import { useEffect, useState } from 'react';

import InfoIcon from '../../../assets/svg/info.svg';
import { useAuth } from '../../../lib/auth';
import { StatusPopupProps } from './props';
import { inferParkingModal } from './utils/inferTypes';

import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

export const StatusPopup = ({
  parking,
  latestEvent,
  userSubscriptions,
}: StatusPopupProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const [hideModal, setHideModal] = useState<boolean>(false);
  const { currentUser } = useAuth();

  let selectedModalType = inferParkingModal(
    currentUser,
    t,
    userSubscriptions,
    parking,
    latestEvent
  );

  // useEffect(() => {
  //   if (latestEvent?.twilioCallResponse) {
  //     setHideModal(false);
  //   } else {
  //     setHideModal(true);
  //   }
  // }, [latestEvent?.twilioCallResponse, latestEvent?.updatedAt]);

  if (!selectedModalType) {
    return null;
  }

  return (
    <div
      className={`absolute bg-white z-status-popup border border-black  left-[36px] right-[36px] top-[32px] shadow-2xl rounded-lg flex p-4`}
    >
      <div className="w-12 h-12 xxs:w-16 xxs:h-16">
        <InfoIcon />
      </div>
      <div className="ml-4 flex flex-col">
        <div className={`text-black text-xs xxs:text-md font-bold mb-2 `}>
          {selectedModalType.title}
        </div>
        <div
          className="text-xs xxs:text-sm"
          dangerouslySetInnerHTML={{ __html: selectedModalType.description }}
        />
      </div>

      {/* {selectedModalType.type === 'unavailable' && (
        <Button
          Icon={CheckIcon}
          loading={isLoadingRewardCreate}
          onClick={() => setHideModal(true)}
          type="primary"
        >
          Ok
        </Button>
      )} */}
    </div>
  );
};
