import { Loader } from '../../Loader';
import { ButtonProps } from './props';

export const Button = ({
  className = '',
  type = 'primary',
  Icon,
  disabled = false,
  loading = false,
  onClick,
  children,
}: ButtonProps) => {
  return (
    <button
      className={`py-3 px-6 text-sm w-full  font-bold rounded-general h-button disabled:bg-[#C5C5C5] disabled:border-none ${className}
        ${type === 'primary' && 'text-white bg-primary border-none'}
        ${
          type === 'outline' &&
          'text-primary bg-transparent border border-primary stroke-transparent fill-primary'
        }
        ${
          type === 'link' &&
          'bg-transparent border-none text-primary text-right underline'
        }
        ${(disabled || loading) && 'cursor-not-allowed'}`}
      onClick={onClick}
      disabled={disabled || loading}
    >
      <div className="flex justify-center items-center">
        {loading ? (
          <Loader size={24} isInverted={type !== 'outline'} />
        ) : (
          <>
            {Icon && (
              <div className="mr-2">{<Icon width={22} height={22} />}</div>
            )}
            {children}
          </>
        )}
      </div>
    </button>
  );
};
