import { gql } from "@apollo/client";

export const CreateReviewMutation = gql`
  mutation CreateReviewMutation(
    $rating: Int!
    $description: String
    $userId: ID!
  ) {
    createReview(
      data: {
        rating: $rating
        description: $description
        user: { connect: { id: $userId } }
      }
    ) {
      rating
      description
    }
  }
`;
